<template>
  <div class="info-box-container relative">
    <div class="info-box-wrap no-select ghost">
      <div class="info-box info-box-image">
        <div class="polaroid">
          <div class="polaroid-frame absolute"></div>
        </div>
      </div>
    </div>
    <div class="info-box-wrap corporeal" :class="{ open: open }" ref="box">
      <div
        class="info-box info-box-image"
        :class="`caption-align-${captionAlign}`"
      >
        <div class="polaroid" ref="polaroid">
          <div class="polaroid-image parallax-item" data-depth="0.8">
            <img :src="data.thumb" :alt="data.desc" ref="image" />
          </div>
          <div class="polaroid-frame absolute"></div>
        </div>
        <button
          v-if="type == 'modal'"
          class="btn btn-round btn-info-box"
          @mouseover="hoverButton(true)"
          @mouseleave="hoverButton(false)"
          @click="showModal(data.modal)"
          ref="button"
        >
          <i class="ai-feather"></i>
        </button>
        <a
          v-if="type == 'link'"
          class="btn btn-round btn-linkbox"
          @mouseover="hoverButton(true)"
          @mouseleave="hoverButton(false)"
          ref="button"
          target="blank"
          :href="data.link"
        >
          <i class="ai-link2"></i>
        </a>
        <button
          v-if="type == 'image'"
          class="btn btn-round btn-imagebox"
          @mouseover="hoverButton(true)"
          @mouseleave="hoverButton(false)"
          @click="openLightbox(imageData)"
          ref="button"
        >
          <i class="ai-camera"></i>
        </button>
        <div v-if="type == 'image'" class="image-box-caption" ref="caption">
          <media-caption
            v-if="data.desc"
            class="absolute imagebox-caption"
            :textAlign="textAlign"
          >
            <template v-slot:desc>
              {{ data.desc }}
            </template>
            <template v-slot:sub v-if="!hideSubUnderPolaroid">
              <span v-html="data.sub"></span>
            </template>
          </media-caption>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  partComponent,
  lightboxFunctions,
  modalFunctions,
} from "@/mixins/core.js";

import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "ImageBox",
  components: {
    MediaCaption,
  },
  mixins: [partComponent, lightboxFunctions, modalFunctions],
  props: {
    data: {
      type: Object,
    },
    captionAlign: {
      type: String,
      default: "left",
    },
    timescaleReverse: {
      type: Number,
      default: 1.8,
    },
    type: {
      type: String,
      default: "image",
    },
    hideSubUnderPolaroid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textAlign() {
      return this.captionAlign == "left" ? "right" : "left";
    },
    imageData() {
      if (!this.data.image) return false;

      return {
        type: "image",
        item: this.data.image,
        title: this.data.desc,
        sub: this.data.sub,
        poster: false,
      };
    },
  },
  data() {
    return {
      timelineHover: null,
      open: false,
    };
  },
  mounted() {
    const vm = this;
    const { box, image, polaroid, button } = this.$refs;

    //enter timeline
    const tl = new TimelineMax({
      paused: true,
      onComplete: function () {
        vm.open = true;
      },
    });

    tl.set(box, {
      autoAlpha: 1,
    })
      .from(polaroid, 1, {
        height: 0,
        ease: Power3.easeInOut,
      })
      .add("polaroidIn")
      .from(
        image,
        1.6,
        {
          scale: 1.2,
          ease: Power2.easeOut,
        },
        0
      )
      .from(
        button,
        0.3,
        {
          autoAlpha: 0,
          ease: Power2.easeIn,
        },
        "polaroidIn-=0.6"
      );

    if (this.imageData) {
      const caption = this.$refs.caption;

      tl.from(
        caption,
        0.6,
        {
          autoAlpha: 0,
          y: "+=20",
          ease: Power2.easeIn,
        },
        "polaroidIn-=0.4"
      );
    }

    this.timeline = tl;

    //hover timeline
    const tlHover = new TimelineMax({
      paused: true,
      ease: Power0.noEase,
    });

    tlHover.to(
      image,
      0.7,
      {
        scale: 1.1,
        ease: Power2.easeInOut,
      },
      0
    );

    this.timelineHover = tlHover;
  },
  methods: {
    hoverButton(play) {
      play ? this.timelineHover.play() : this.timelineHover.reverse();
    },
  },
};
</script>

<style scoped>
img {
  display: block;
}
.items-shown .polaroid {
  height: 29.625em !important;
}
.polaroid {
  width: 24.875em;
  height: 29.625em;
  position: relative;
  overflow: hidden;
}
.polaroid-frame {
  background: url(https://media.londolozi.com/africa/ui/londolozi-polaroid.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 1;
  width: 24.875em;
  height: 29.625em;
  display: block;
}
.polaroid-image {
  width: 22.75em;
  height: 22.75em;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  top: 2.4em !important;
  left: 1.1em !important;
  display: block;
  transform-style: flat !important;
  backface-visibility: hidden !important;
}
.polaroid-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.imagebox-caption {
  transform: translate(50%, 0);
}
.imagebox-caption.caption-align-right {
  transform: translate(-50%, 0);
  width: 100%;
}

.info-box-wrap {
  font-size: 1rem;
  position: relative;
  transform-origin: top center;
  padding-top: 1.1rem;
  padding-bottom: 2.8rem;
  opacity: 0;
  display: block;
}
.info-box-image {
  position: relative;
}

.btn-round {
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 0 0 rgba(55, 152, 251, 0.6);
  transition: none;
  z-index: 2;
}
.items-shown .btn-round {
  transition: 0.3s ease-in-out;
}
.info-box-wrap.open .btn-round {
  animation: pulse 1.25s 2 cubic-bezier(0.66, 0, 0, 1);
}
</style>
