<template>
  <modal
    :name="modalName"
    :preMount="false"
    :disableBackdrop="true"
    :contentTransition="'lightbox-in'"
    :backdropTransition="'modal-overlay-in'"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="closed"
    v-if="viewLoaded"
  >
    <div
      class="modal modal-lightbox modal-lightbox-image"
      :class="{ 'item-ready': itemLoaded, 'item-shown': itemShown }"
      ref="container"
      :id="`modal-${modalName}`"
    >
      <div class="modal-lightbox-loader">
        <div class="spinner">
          <div></div>
        </div>
      </div>
      <div class="lightbox-container hide-initial">
        <img
          :src="lightboxData.item"
          :alt="lightboxData.title"
          ref="item"
          class="img"
          v-if="lightboxData.type == 'image'"
        />

        <div
          class="media-caption caption"
          ref="caption"
          v-show="lightboxData.title || lightboxData.sub"
        >
          <div v-if="lightboxData.title" class="caption-main din uppercase">
            {{ lightboxData.title }}
          </div>
          <div v-if="lightboxData.sub" class="caption-sub italic grey2">
            <span v-html="lightboxData.sub"></span>
          </div>
        </div>
      </div>
      <button
        class="btn-close btn absolute"
        type="button"
        @click="hideModal"
        ref="modalButton"
      ></button>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  loadImages,
  eventListeners,
  storeScrollBarWidth,
  zoomFunctions,
} from "@/mixins/core.js";

export default {
  name: "ModalLightbox",
  props: [],
  mixins: [loadImages, eventListeners, storeScrollBarWidth, zoomFunctions],
  computed: {
    ...mapState([
      "modalOpen",
      "menuOpen",
      "viewReady",
      "viewLoaded",
      "lightboxData",
    ]),
    bodyEl() {
      return document.body;
    },
  },
  data() {
    return {
      modalName: "lightbox",
      timeline: null,
      itemLoaded: false,
      itemShown: false,
    };
  },
  watch: {
    $route(to, from) {
      //if removing modal hash, close modal
      if (
        to.name === from.name &&
        to.path === from.path &&
        from.hash.includes("#modal")
      ) {
        this.hideModal();
      }
    },
    viewReady(val) {
      if (val) {
        //open modal if has in url
        if (this.$route.hash === `#modal-${this.modalName}`) {
          this.$router.push({ hash: false });
        }
      }
    },
    itemLoaded(val) {
      if (val) {
        console.log("%c%s", "color: red;", "item loaded now");
        const vm = this;
        setTimeout(vm.playImageTimeline, 400);
      }
    },
  },
  methods: {
    ...mapActions(["showModal", "hideModal", "toggleStatus"]),
    playImageTimeline() {
      const vm = this;
      if (this.timeline) {
        this.timeline.play();
      } else {
        console.log("%c%s", "color: red;", "timeline not ready set timeout");
        setTimeout(vm.playImageTimeline, 300);
      }
    },
    toggleBodyClass(val) {
      if (val) {
        this.bodyEl.classList.add("no-scroll", "lightbox-open");
      } else {
        this.bodyEl.classList.remove("no-scroll", "lightbox-open");
      }
    },
    lightBoxIn() {
      const vm = this;
    },
    itemIsShown() {
      this.itemShown = true;
    },
    createTimeline() {
      const { item, caption, modalButton } = this.$refs;

      const tl = new TimelineMax({
        onComplete: this.lightBoxIn,
        paused: true,
      });

      tl.set(".lightbox-container", {
        autoAlpha: 1,
      })
        .add("startTl", 0.3)
        .from(
          item,
          0.4,
          {
            autoAlpha: 0,
            ease: Power3.easeOut,
          },
          "startTl"
        )
        .from(
          item,
          1,
          {
            y: "+=20%",
            ease: Power3.easeOut,
          },
          "startTl"
        )
        .from(
          caption,
          0.6,
          {
            autoAlpha: 0,
            x: "+=20",
            ease: Power2.easeIn,
          },
          "startTl+=0.4"
        )
        .to(
          modalButton,
          0.6,
          {
            autoAlpha: 1,
            ease: Power2.easeIn,
          },
          "startTl+=0.4"
        )
        .from(
          modalButton,
          0.6,
          {
            autoAlpha: 0,
            x: "-=20",
            ease: Power2.easeIn,
          },
          "startTl+=0.4"
        )
        .call(this.itemIsShown, null, this, "+=0.2");

      this.timeline = tl;
    },
    beforeOpen() {
      const vm = this;
      this.addScrollbarPaddingToStore(this.currentScrollBarWidth);

      this.itemShown = false;
      this.itemLoaded = false;
      this.preloadImages([this.lightboxData.item], function () {
        console.log(
          "%c%s",
          "color: red;",
          "done loading image, playing timeline"
        );
        vm.itemLoaded = true;
      });

      setTimeout(function () {
        vm.toggleBodyClass(true);
      }, 300);

      //add hash to url for back functionality
      this.$router.push({ hash: `modal-${this.modalName}` });

      //add event listeners
      this.addEventListeners();

      //close menu if open
      if (this.menuOpen) {
        this.toggleStatus({
          type: "menuOpen",
          status: false,
        });
      }
    },
    opened() {
      this.resetAndAllowZoom();
      this.createTimeline();
    },
    beforeClose() {
      const vm = this;
      setTimeout(function () {
        vm.toggleBodyClass(false);
      }, 100);

      this.timeline.timeScale(2);
      this.timeline.reverse();

      //remove event listeners
      this.removeEventListeners();
    },
    closed() {
      this.removeScrollbarPaddingToStore();
      this.timeline.kill();
      this.timeline = null;

      //remove modal hash
      this.$router.push({ hash: false });
    },
    onEscape(e) {
      if (this.modalOpen && e.keyCode == 27) {
        this.hideModal();
      }
    },
    onClick(e) {
      if (this.modalOpen && e.target.className === "modal-content-wrapper") {
        this.hideModal();
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modal-lightbox-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.item-shown .modal-lightbox-loader {
  opacity: 0;
  transition: opacity 0.25 ease-out;
}

.modal-lightbox {
  opacity: 1;
}
.lightbox-container {
  opacity: 0;
}

.btn-close {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  opacity: 0;
}

.lightbox-in-enter-active {
}
.lightbox-in-leave-active {
  transition: 0.8s;
}
.lightbox-in-enter,
.lightbox-in-leave-to {
}
</style>
