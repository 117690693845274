<template>
  <div class="part-component timeline" :class="typeClass">
    <div class="timeline-slider-wrap" :class="currentSlideClass">
      <div class="timeline-inner-wrap">
        <div class="ink-wide">
          <img :src="ink" />
        </div>
        <div class="timeline-slider content-slider">
          <vue-glide
            :options="options"
            :type="'slider'"
            v-model="activeSlide"
            v-on:glide:mount-after="sliderMounted"
            v-on:glide:run-before="textExpanded = null"
          >
            <vue-glide-slide v-for="(item, i) in data.items" :key="i">
              <div class="slide-content layout-row layout-align-center-start">
                <div
                  class="slide-text-wrap layout-column layout-align-center-end"
                >
                  <h2
                    class="
                      slide-title
                      italic
                      grey2
                      parallax-relative parallax-item
                    "
                    data-depth="2.2"
                  >
                    {{ item.title }}
                  </h2>
                  <p
                    v-if="item.text"
                    class="
                      slide-text
                      text-right
                      parallax-relative parallax-item
                    "
                    data-depth="1.7"
                  >
                    <vue-simple-scrollbar>
                      <span v-html="item.text"></span>
                    </vue-simple-scrollbar>
                  </p>
                  <p
                    v-if="data.type === 'link'"
                    class="
                      slide-text slide-sub
                      layout-column layout-align-start-end
                      parallax-flex parallax-item
                      italic
                      grey2
                    "
                    data-depth="2.2"
                  >
                    <span class="book-by">{{ item.type }} by</span>
                    <span class="book-sub">{{ item.sub }}</span>
                    <a class="readmore din" target="_blank" :href="item.link"
                      >Learn more</a
                    >
                  </p>
                  <div
                    class="line line-blue parallax-item"
                    data-depth="0.8"
                  ></div>
                </div>
                <div class="slide-image">
                  <image-box
                    v-if="data.type == 'link'"
                    :show="showImages"
                    class="parallax-item parallax-relative section-imagebox"
                    data-depth="1.2"
                    :type="data.type"
                    :data="{ thumb: item.thumb, link: item.link }"
                  ></image-box>
                  <image-box
                    v-else
                    :show="showImages"
                    class="parallax-item parallax-relative section-imagebox"
                    data-depth="1.2"
                    :captionAlign="'left'"
                    :data="{
                      thumb: item.thumb,
                      image: item.image,
                      desc: item.caption,
                      sub: item.sub,
                    }"
                    :hideSubUnderPolaroid="true"
                  ></image-box>
                </div>
                <div
                  class="line line-grey parallax-item"
                  data-depth="0.6"
                ></div>
                <div
                  class="slide-footprint parallax-item"
                  data-depth="1.4"
                ></div>
              </div>
            </vue-glide-slide>
          </vue-glide>
        </div>
      </div>
      <button
        class="btn-square btn-icon btn-slider btn-slider-prev parallax-item"
        data-depth="0.7"
        @click="advanceSlide(-1)"
      >
        <i class="ai-left"></i>
      </button>
      <button
        class="btn-square btn-icon btn-slider btn-slider-next parallax-item"
        data-depth="0.7"
        @click="advanceSlide(1)"
      >
        <i class="ai-right"></i>
      </button>
      <div class="bird absolute center-left">
        <img
          :src="decal"
          class="parallax-item parallax-relative"
          data-depth="0.9"
        />
      </div>
    </div>
    <div
      class="
        glide__bullets
        layout-row layout-align-center-center
        parallax-flex parallax-item
      "
      data-depth="0.9"
    >
      <button
        v-for="(item, i) in data.items"
        :key="i"
        class="glide__bullet btn-bullet"
        :data-glide-dir="`=${i}`"
        @click="activeSlide = i"
        :class="{ active: activeSlide == i }"
      ></button>
    </div>
    <modal-lightbox></modal-lightbox>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

import "vue-glide-js/dist/vue-glide.css";

import { Glide, GlideSlide } from "vue-glide-js";

import ModalLightbox from "@/components/ui/ModalLightbox.vue";
import ImageBox from "@/components/ui/ImageBox.vue";

import VueSimpleScrollbar from "vue-simple-scrollbar";

export default {
  name: "Timeline",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    ImageBox,
    ModalLightbox,
    VueSimpleScrollbar,
  },
  mixins: [partComponent],
  props: ["show", "data", "decal", "ink"],
  data() {
    return {
      showImages: false,
      activeSlide: 0,
      options: {
        gap: 0,
        focusAt: 0,
        startAt: 0,
        animationDuration: 600,
        perView: 2,
        rewind: false,
        keyboard: true,
        dragThreshold: 10,
        classes: {
          slider: "slider",
          carousel: "carousel",
          activeSlide: "active",
        },
      },
    };
  },
  computed: {
    typeClass() {
      return this.data.type ? `timeline-${this.data.type}` : "timeline-image";
    },
    totalSlides() {
      return this.data.items.length;
    },
    currentSlideClass() {
      let currentSlide = `current-${this.activeSlide}`;

      if (this.totalSlides - 1 == this.activeSlide) {
        currentSlide += " current-last";
      } else if (0 == this.activeSlide) {
        currentSlide += " current-first";
      }

      return currentSlide;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.showSlider();
      }
    },
  },
  methods: {
    advanceSlide(increment) {
      let goToSlide = this.activeSlide + increment;

      if (goToSlide > this.totalSlides - 1 || goToSlide < 0) {
        return;
      }

      this.activeSlide = goToSlide;
    },
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.to(
        ".timeline-inner-wrap",
        0.7,
        {
          css: {
            borderColor: "rgba(0,0,0,0.09)",
          },
          ease: Power2.easeOut,
        },
        0
      )
        .from(
          ".line-grey",
          0.7,
          {
            width: 0,
            ease: Power2.easeOut,
          },
          0
        )
        .from(
          ".slide-title",
          0.7,
          {
            x: "-50",
            autoAlpha: 0,
            ease: Power2.easeOut,
          },
          0
        )
        .from(
          ".line-blue",
          0.7,
          {
            width: 0,
            ease: Power2.easeOut,
          },
          0
        )
        .from(
          ".slide-text",
          0.6,
          {
            autoAlpha: 0,
            y: "+=20",
            ease: Power2.easeIn,
          },
          0.2
        )
        .staggerFrom(
          ".btn-bullet",
          0.5,
          {
            autoAlpha: 0,
            y: "+=10",
            ease: Power2.easeInOut,
          },
          0.05,
          0
        )
        .from(
          ".bird img",
          0.6,
          {
            autoAlpha: 0,
            ease: Power1.easeIn,
            y: 20,
          },
          0.4
        )
        .from(
          ".btn-slider-prev",
          0.6,
          {
            autoAlpha: 0,
            x: "+=20",
            ease: Power1.easeIn,
          },
          0.4
        )
        .from(
          ".btn-slider-next",
          0.6,
          {
            autoAlpha: 0,
            x: "-=20",
            ease: Power1.easeIn,
          },
          0.4
        )
        .from(
          ".ink-wide",
          1,
          {
            ease: Power1.easeOut,
            autoAlpha: 0,
          },
          0.2
        )
        .from(
          ".slide-footprint",
          0.6,
          {
            ease: Power1.easeIn,
            autoAlpha: 0,
            x: "-=40",
          },
          0.4
        )
        .call(this.showTimelineImages, null, this, 0.2);

      this.timeline = tl;
    },
    showTimelineImages() {
      this.showImages = !this.showImages;
    },
    sliderMounted() {
      console.log("slide mounted");
    },
    showSlider() {},
  },
  created() {},
};
</script>

<style scoped>
@import "~vue-simple-scrollbar/dist/vue-simple-scrollbar.css";

.bird {
  top: 23.4% !important;
  left: -0.3% !important;
  z-index: 2;
  width: 6.5em;
}
.bird img {
  display: block;
  max-width: 100%;
}

.timeline-inner-wrap {
  overflow: hidden;
  position: relative;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.slide-footprint {
  background: url("https://media.londolozi.com/africa/decals/londolozi_africa_decal_footprints.png");
  background-size: 100%;
  width: 7.813em;
  height: 5.438em;
  position: absolute;
  right: -4.35em;
  top: 10.1em !important;
  z-index: 1;
  left: auto !important;
}
.timeline-slider .glide__slides li:last-child .slide-footprint {
  display: none !important;
}

.timeline-link .slide-title {
  font-size: 2.1em;
  margin: 2.238em 0 1.048em;
  height: 3.6em;
  line-height: 1.2;
  max-width: 7em;
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
  color: #666;
}
.timeline-link .slide-sub {
  margin: 0;
  font-size: 1.6em;
  line-height: 1.3em;
}

.timeline-link .slide-sub span {
  max-width: 100%;
  text-align: right;
}

.timeline-link .slide-sub span.book-sub {
  margin-bottom: 0.7em;
}
.timeline-link .slide-sub span.book-by {
  font-size: 0.85em;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .bird {
    width: 5em;
  }
  .slide-footprint {
    top: 9em !important;
    right: -2em;
    width: 7em;
    height: 5em;
  }
  .timeline-link .slide-title {
    margin: 1.47em 0 1.048em;
  }
}

@media screen and (max-width: 639px) {
}

@media screen and (max-width: 540px) {
}

@media screen and (max-width: 520px) {
}

@media screen and (max-width: 479px) {
  .timeline-link .slide-title {
    font-size: 1.9em;
    margin: 1.95em 0 1.048em;
  }
  .timeline-link .slide-sub {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 440px) {
  .timeline-link .slide-title {
    font-size: 1.9em;
    margin: 1.5em 0 1.048em;
  }
}
@media screen and (max-width: 420px) {
  .bird {
    width: 4em;
    top: 26.5% !important;
  }
  .timeline-link .slide-title {
    font-size: 17px;
    margin: 1.8em 0 3em;
  }
  .timeline-link .slide-sub {
    font-size: 14px;
  }
}
@media screen and (max-width: 390px) {
  .bird {
    top: 25% !important;
  }
}
</style>
